import React, { useEffect } from "react";
import Headers from "../components/headers/Headers";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar";
import Footer from "components/Footers/Footer";
import { Helmet } from "react-helmet-async";
import NedenBiz from "components/NedenBiz/NedenBiz";
import GoogleReviews from "components/GoogleReviews/GoogleReviews";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";


const Home = () => {

  React.useEffect(() => {
    document.body.classList.add("sections-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    return function cleanup() {
      document.body.classList.remove("sections-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });


    useEffect(() => {
      // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
      const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin
    
      if (targetLink) {
        // Otomatik tıklama
        targetLink.click();
        const imgElement = document.querySelector('.gt-current-lang img');
        const newImgSrc = 'https://cdn.gtranslate.net/flags/32/tr.png'; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
        console.log(imgElement)
        imgElement.src = newImgSrc;
        imgElement.width="32"
        imgElement.height="32"
      }
    }, []);

  return (
    <>
      <Helmet>
        <title>
        Ekşioğlu Hukuk | Anasayfa
        </title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Ekşioğlu Hukuk Avukatlık Bürosu uluslararası vizyona sahip, müvekkillerinin yasal haklarını mesleki etik değerlerine bağlı kalarak korumayı benimsemiş bir avukatlık bürosudur. 2021 yılında Alper Kaya tarafından İstanbul'da kurulmuştur."
          data-rh="true"
        />
        <link rel="canonical" href="https://www.eksiogluhukukofisi.com"/>
        <meta property="og:title" content="Ekşioğlu Hukuk | Anasayfa" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Hukuk danışmanlığı" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.eksiogluhukukofisi.com" />
      </Helmet>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        
        <Headers/>
        {/* <Hizmetlerimiz/> */}
        <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        
        <NedenBiz/>
       
        <GoogleReviews/>
        <Footer />
      </div>
    </>
  );
};

export default Home;
