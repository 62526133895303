import React, { createContext, useContext, useState, useEffect } from 'react';

// Context oluşturma
const SizeContext = createContext();

// Context sağlayıcısı oluşturma
export const SizeProvider = ({ children }) => {
  // anlık oynamada 1 px aşağısını düşün
  const [under1200, setUnder1200] = useState(window.innerWidth < 1200);
  const [under992, setUnder992] = useState(window.innerWidth < 992);
  const [between849to767, setBetween849to767] = useState(window.innerWidth < 849 && window.innerWidth > 767);
  const [under576, setUnder576] = useState(window.innerWidth < 576)
  const [above960,setAbove960] = useState(window.innerWidth > 960)
  const [under767,setUnder767] = useState(window.innerWidth <767)
  const [under376,setUnder376] = useState(window.innerWidth <376)
  const [under427,setUnder427] = useState(window.innerWidth < 427)

  useEffect(() => {
    const handleResize = () => {
      setUnder1200(window.innerWidth < 1200);
      setUnder992(window.innerWidth < 992);
      setBetween849to767(window.innerWidth < 849 && window.innerWidth > 767)
      setAbove960(window.innerWidth > 960)
      setUnder767(window.innerWidth < 767)
      setUnder376(window.innerWidth < 376)
      setUnder427(window.innerWidth < 427)
    };

    window.addEventListener("resize", handleResize);

    // Component unmount olduğunda event listener'ı kaldır
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SizeContext.Provider value={{ under1200, under992,between849to767,under576,above960,under767,under376,under427 }}>
      {children}
    </SizeContext.Provider>
  );
};

// Custom hook oluşturma
export const useSize = () => useContext(SizeContext);
