import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

// Stil dosyaları
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";

// Sayfalar
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Blog from "./pages/Blog";
import Videolar from "./pages/Videolar";

// Özel Hukuk
import SozlesmelerVeBorclarHukuku from "./pages/SozlesmelerVeBorclarHukuku";
import IsHukuku from "./pages/IsHukuku";
import AileHukuku from "./pages/AileHukuku";
import MirasHukuku from "./pages/MirasHukuku";
import GayrimenkulHukuku from "./pages/GayrimenkulHukuku";

// Kamu Hukuku
import CezaHukuku from "./pages/CezaHukuku";
import IdareHukuku from "./pages/IdareHukuku";
import VergiHukuku from "./pages/VergiHukuku";
import GumrukHukuku from "./pages/GumrukHukuku";
import VatandaslikHukuku from "./pages/VatandaslikHukuku";

// Ticaret Hukuku
import TicaretHukuku from "./pages/TicaretHukuku";
import DenizTicaretiHukuku from "./pages/DenizTicaretiHukuku";
import RekabetHukuku from "./pages/RekabetHukuku";
import FikriMulkiyetHukuku from "./pages/FikriMulkiyetHukuku";
import IcraIflasHukuku from "pages/IcraIflasHukuku";

// Tüketici ve Sağlık Hukuku
import SaglikHukuku from "./pages/SaglikHukuku";
import TuketiciHukuku from "./pages/TuketiciHukuku";
import SigortaHukuku from "./pages/SigortaHukuku";
import SporHukuku from "pages/SporHukuku";

// Teknoloji ve Verilerin Korunması Hukuku
import BilisimHukuku from "./pages/BilisimHukuku";
import KisiselVerileriKorumaKanunu from "./pages/KisiselVerileriKorumaKanunu";

import BlogRouter from "components/Blog/BlogRouter";


// PrimeReact
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

// HelmetProvider
import { HelmetProvider } from "react-helmet-async";

// SizeProvider
import { SizeProvider } from "components/SizeControl/SizeControl";



const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <PrimeReactProvider>
    <SizeProvider>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/hakkimizda" element={<AboutUs />} />
            
            
            <Route path="/iletisim" element={<ContactUs />} />

            <Route path="/ozel-hukuk/sozlesmeler-ve-borclar-hukuku" element={<SozlesmelerVeBorclarHukuku/>} />
            <Route path="/ozel-hukuk/aile-hukuku" element={<AileHukuku/>} />
            <Route path="/ozel-hukuk/miras-hukuku" element={<MirasHukuku/>} />
            <Route path="/ozel-hukuk/ticaret-hukuku" element={<TicaretHukuku/>} />
            <Route path="/ozel-hukuk/gayrimenkul-hukuku" element={<GayrimenkulHukuku/>} />

            <Route path="/kamu-hukuku/ceza-hukuku" element={<CezaHukuku/>} />
            <Route path="/kamu-hukuku/idare-hukuku" element={<IdareHukuku/>} />
            <Route path="/kamu-hukuku/vergi-hukuku" element={<VergiHukuku/>} />
            <Route path="/kamu-hukuku/gumruk-hukuku" element={<GumrukHukuku/>} />
            <Route path="/kamu-hukuku/vatandaslik-hukuku" element={<VatandaslikHukuku/>} />

            <Route path="/ekonomi-ve-ticaret-hukuku/icra-iflas-hukuku" element={<IcraIflasHukuku/>} />
            
            <Route path="/ekonomi-ve-ticaret-hukuku/deniz-ticareti-hukuku" element={<DenizTicaretiHukuku/>} />
            <Route path="/ekonomi-ve-ticaret-hukuku/rekabet-hukuku" element={<RekabetHukuku/>} />
            <Route path="/ekonomi-ve-ticaret-hukuku/fikri-mulkiyet-hukuku" element={<FikriMulkiyetHukuku/>} />

            <Route path="/sosyal-ve-idari-hukuk/is-ve-sosyal-guvenlik-hukuku" element={<IsHukuku/>} />
            <Route path="/sosyal-ve-idari-hukuk/tuketici-hukuku" element={<TuketiciHukuku/>} />
            <Route path="/sosyal-ve-idari-hukuk/saglik-hukuku" element={<SaglikHukuku/>} />
            <Route path="/sosyal-ve-idari-hukuk/spor-hukuku" element={<SporHukuku/>} />
            

            <Route path="/sigorta-tahkim-hukuku" element={<SigortaHukuku/>} />
            <Route path="/teknoloji-ve-internet-hukuku/bilisim-hukuku" element={<BilisimHukuku/>} />
            <Route path="/teknoloji-ve-internet-hukuku/kisisel-verilerin-korunmasi-hukuku" element={<KisiselVerileriKorumaKanunu/>} />
            
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/*" element={<BlogRouter/>}/>
            
            {/* <Route path="/" element={<Videolar />} />  */}
            {/* 404 yönlendirme */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </SizeProvider>
  </PrimeReactProvider>
);
