// PortalModal.js
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const PortalModal = ({ visible, onHide }) => {
  const buttonStyle = {
    color: '#fff',
    width: '100%',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center'
  };
  const dialogWidth = window.innerWidth < 768 ? '90vw' : '30vw';

  return (
    <Dialog header="EKŞİOĞLU PORTAL" visible={visible} onHide={onHide} style={{ width: dialogWidth }}>
      <div className="p-d-flex p-flex-column p-ai-center">
        <Button label="MÜVEKKİL GİRİŞİ" style={{ ...buttonStyle, backgroundColor: '#f96332' }} onClick={() => window.open('https://eksioglu-hukuk.vercel.app/dosya-sorgulama', '_blank')} />
        <Button label="PERSONEL GİRİŞİ" style={{ ...buttonStyle, backgroundColor: '#3b82f6' }} onClick={() => window.open('https://eksioglu-hukuk.vercel.app/auth/login', '_blank')} />
      </div>
      
    </Dialog>
  );
};

export default PortalModal;
