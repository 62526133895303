import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const SigortaHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | SİGORTA TAHKİM HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SİGORTA (TAHKİM) HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Sigorta hukuku, sigorta sözleşmeleriyle ilgili anlaşmazlıkların çözümlenmesinde uygulanan hukuk dalıdır. Sigorta şirketi ve sigortalı arasında zaman zaman anlaşmazlıklar ortaya çıkabilir ve bu anlaşmazlıkların çözümü için sigorta hukuku kullanılır.
            </p>
            <br></br>
            <p>
            Sigorta sözleşmelerinden doğan anlaşmazlıklar neticesinde, sigorta şirketine başvuru halinde bir geri dönüş sağlanmaz veya eksik bir dönüş sağlanır ise kişinin/kurumun hakkını elde edebilmesi için Sigorta Tahkim Komisyonu’na başvurması gereklidir. Bu nedenlerle Avukatlık Bürosu olarak müvekkillerimizin taraf olarak yer aldığı sigorta uyuşmazlıklarında; Sigorta Tahkim Komisyonu nezdindeki başvurularında, genel mahkemelerde görülen sigorta hukukuna ilişkin davalarında, gerekli hallerde arabuluculuk süreçlerinde etkin bir şekilde temsil ederek alternatif çözüm yollarını da içeren bir avukatlık hizmeti vermekteyiz.
            </p>
            <br></br>
            <p>
            Büromuzun uzman kadrosu, anlaşmazlıklarınızın hızlı ve etkili bir şekilde çözümlenmesi ve uzun ve maliyetli yargılama süreçlerinden kaçınılması için sizin için her daim yanınızda yer almaktadır.
            </p>



            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="Aracım değer kaybına uygun mu?">
        <p className="m-0">
        Kazada tam kusurlu olmamanız, araçta pert kaydı ve aynı yerden hasar olmaması ve kaza tarihi son iki yıl içerisinde olmalıdır.
        </p>
    </AccordionTab>
    <AccordionTab header="İstanbul dışında yaşıyorum, yine de değer kaybı alabilir miyim?">
        <p className="m-0">
        Değer kaybı tahsili için İstanbul’da bulunmanız gerekmemektedir. Türkiye’nin her yerinden değer kaybı başvurusu için bizimle iletişime geçebilirsiniz.
        </p>
    </AccordionTab>
    <AccordionTab header="Aracımın ne kadar değer kaybına uğradığını nasıl öğrenebilirim, değer kaybı neye göre hesaplanmaktadır?">
        <p className="m-0">
        Araçtaki değer kaybı ekibimizde yer alan alanında uzman eksperler tarafından hesaplanmakta, size değer kaybı ile ilgili bu doğrultuda bilgi verilmektedir. Değer kaybı hesaplaması; 04.11.2021 tarihli KTK Genel Şartları kapsamında hazırlanan ve aracın (kasko değer rayici, km, hasar miktarı, tabloda yer alan kaporta sac aksamı) gibi parametlerinin toplamı sonucu belli olmaktadır.
        </p>
    </AccordionTab>
    <AccordionTab header="Hangi evraklar ile başvuru yapılması gerekir?">
        <p className="m-0">
        Sigorta Tahkim Komisyonuna başvuru yapılırken eksper raporu, kaza fotoğrafları, kaza tespit tutanağı, araç ruhsatı, başvuru ücreti ile sigortaya başvuru yapıldığını ispat edecek evrak ile müracaat edilebilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Kendim de başvuru yapabilir miyim?">
        <p className="m-0">
        Hukuksal yollar açısından her hak sahibinin kendisinin doğrudan başvuru yapma imkanı bulunmaktadır. Ancak bir işi profesyonel takip edilmesi ve nihai sonucun tam olarak elde edilmesi için alanında uzman hukukçular vasıtası ile başvuru yapılması her zaman size kazandıracaktır. Nasıl ki aracı kendimiz onarmayıp kaporta ustasına tamir ettiriyorsak, bu işin de uzmanı vasıtası ile başvurulması değer kaybı tazminatı ve hasar farkının hakkı ile tahsilini sağlayacaktır.
        </p>
    </AccordionTab>
    <AccordionTab header="Karşı tarafın zorunlu trafik sigortası yoksa ne yapmalıyım?">
        <p className="m-0">
        Bu durumda oluşan hasar araç sürücüsü ya da sahibi tarafından karşılanacaktır. Yaşadığınız hasarın karşılanması için sigorta tahkim komisyonuna değil, T.C. mahkemelerine başvurarak alacağınızın tahsil edilmesi için dava açılması gerekmektedir.
        </p>
    </AccordionTab>
    {/* <AccordionTab header="Sigorta Hukuku nedir?">
        <p className="m-0">
            Sigorta hukuku, sigorta sözleşmeleri, sigorta şirketlerinin sorumlulukları, sigortalıların hakları ve sigorta poliçelerinin düzenlenmesi gibi konuları düzenleyen hukuk dalıdır. Sigorta hukuku, sigorta ilişkilerinin düzenlenmesi ve tarafların haklarının korunması amacını taşır.
        </p>
    </AccordionTab>
    <AccordionTab header="Sigorta sözleşmeleri ve türleri nelerdir?">
        <p className="m-0">
            Sigorta sözleşmeleri, sigortalının belirli bir risk karşılığında sigorta şirketine prim ödemesi karşılığında zarar durumunda tazminat alma hakkını sağlar. Hayat sigortası, mal sigortası, sağlık sigortası gibi çeşitli türleri bulunur.
        </p>
    </AccordionTab>
    <AccordionTab header="Sigorta primi ve ödeme koşulları nasıl belirlenir?">
        <p className="m-0">
            Sigorta primi, sigortalının risk durumuna, sigorta şirketinin risk yönetim politikalarına ve teminat seviyesine göre belirlenir. Ödeme koşulları ise sigorta sözleşmesinde belirtilen şartlara göre gerçekleştirilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Sigorta poliçesi nedir?">
        <p className="m-0">
            Sigorta poliçesi, sigorta şirketi ile sigortalı arasında yapılan yazılı sözleşmeyi ifade eder. Poliçe, sigorta kapsamı, prim miktarı, sigorta süresi gibi detayları içerir ve tarafların haklarını belgelendirir.
        </p>
    </AccordionTab>
    <AccordionTab header="Sigorta şirketlerinin sorumlulukları nelerdir?">
        <p className="m-0">
            Sigorta şirketleri, poliçede belirtilen koşullara uygun olarak sigortalının taleplerini değerlendirme, tazminat ödeme ve sigorta sürecini yönetme gibi sorumluluklar taşır. Hukuki düzenlemeler bu sorumlulukların yerine getirilmesini sağlar.
        </p>
    </AccordionTab>
    <AccordionTab header="Sigorta tahkim prosedürü nasıl işler?">
        <p className="m-0">
            Sigorta tahkim prosedürü, tarafların tahkim kuruluşunu seçmesi, iddialarını ve delillerini sunması, tahkim kararının verilmesi ve bu kararın uygulanması aşamalarını içerir. Tahkim prosedürü, adil ve tarafsız bir şekilde yürütülür.
        </p>
    </AccordionTab>
    <AccordionTab header="Tahkim kararlarına itiraz süreci nasıl işler?">
        <p className="m-0">
            Tahkim kararlarına karşı genellikle sınırlı itiraz hakkı bulunur ve bu itirazlar mahkemelerde incelenir. Tahkim yargılaması, mahkeme kararlarına göre daha hızlı ve özel bir çözüm sunma avantajına sahiptir.
        </p>
    </AccordionTab>
    <AccordionTab header="Sigorta tahkiminde uluslararası uygulamalar nelerdir?">
        <p className="m-0">
            Uluslararası sigorta tahkiminde, taraflar genellikle tahkim kurallarını ve uluslararası tahkim mahkemelerini tercih eder. Bu şekilde uluslararası ticarette çıkan sigorta uyuşmazlıkları daha etkin bir şekilde çözüme kavuşturulur.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda sigorta hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine sigorta sözleşmeleri danışmanlığı, tazminat taleplerinin takibi, sigorta şirketleriyle müzakereler ve sigorta hukuku uyuşmazlıklarında hukuki temsil hizmetleri sunmaktadır.
        </p>
    </AccordionTab> */}
</Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default SigortaHukuku;
