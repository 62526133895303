import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const VatandaslikHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
      <title>HİZMETLERİMİZ | VATANDAŞLIK VE YABANCI YATIRIMLAR HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>VATANDAŞLIK VE YABANCI YATIRIMLAR HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="VATANDAŞLIK VE YABANCI YATIRIMLAR HUKUKU"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Yabancılar hukuku, bir devletin kendi sınırları dahiline gelen yabancılar için uyguladığı kanunların oluşturduğu hukuk dalıdır. Yabancılar Hukuku genel anlamda mütekabiliyet ilkesine dayanmakta olup, yabancılara Türkiye’de uygulanacak olan hukuk kuralları yabancının vatandaşı olduğu ülkeye göre farklılık göstermektedir.
            </p>
            <br></br>
            <p>
            <b>Ekşioğlu Hukuk Bürosu</b> olarak; Türkiye’de yaşamak isteyen yabancıların oturma izni, çalışma izni ve vatandaşlık kazanımı prosedürlerinin takibi noktasında müvekkillerimize danışmanlık hizmeti sunmaktayız.
            </p>
            <br></br>
            <p>
            Bu kapsamda; yabancıların oturma izni, oturma izni uzatma başvurularının yapılması, takibi ve sonuçlandırılması, Türkiye’de istihdam edilmesi planlanan yabancıların çalışma izni, çalışma izin uzatım başvurusunun yapılması, takibi ve sonuçlandırılması, Türkiye’de çalışma izni edinecek kişilerin de eşi ve çocuklarına da destekleyici yoluyla aile ikamet izni başvurusunun yapılması, takibi ve sonuçlandırılması ve Türk Vatandaşlığı Kanunu kapsamında, Türk Vatandaşlığını kazanmak etmek isteyen yabancıların vatandaşlık başvuru işlemlerinin yapılması, takibi ve sonuçlandırılması noktasında müvekkillerimize hizmet vermekteyiz.
            </p>



            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="Türk Vatandaşlığının yatırım yolu ile kazanılmasında gerekli olan yatırım şartları nelerdir?">
        <p className="m-0">
        -En az 500.000 Amerikan Doları veya karşılığı döviz ya da karşılığı Türk Lirası tutarında sabit sermaye yatırımı gerçekleştirmek<br/>
        -En az 400.000 Amerikan Doları veya karşılığı döviz ya da karşılığı Türk Lirası tutarında taşınmazı satın alıp tapu kayıtlarına üç yıl satılmaması şerhi koydurmak<br/>
        -En az 50 kişilik istihdam oluşturmak,<br/>
        -En az 500.000 Amerikan Doları veya karşılığı döviz ya da karşılığı Türk Lirası tutarında mevduatı Türkiye’de faaliyet gösteren bankalara yatırıp üç yıl tutacağına dair taahhütte bulunmak<br/>
        -En az 500.000 Amerikan Doları veya karşılığı döviz ya da karşılığı Türk Lirası tutarında Devlet borçlanma araçlarını satın alıp üç yıl tutacağına dair taahhütte bulunmak
        </p>
    </AccordionTab>
    <AccordionTab header="Türk Vatandaşlığının yatırım yolu ile kazanılmasında izlenmesi gereken süreç nedir?">
        <p className="m-0">
        Yönetmelik’in 20. maddesinde belirtilen yatırım şartlarından birinin yerine getirilmesi ve ilgili kurumdan Uygunluk Belgesi’nin alınması, 6458 sayılı Kanun’un 31 inci maddesinin birinci fıkrasının (j) bendi uyarınca kısa dönem ikamet izni alınması ve son olarak Yatırım yoluyla Türk Vatandaşlığı kazanılması için ikamet edilen İl Nüfus ve Vatandaşlık Müdürlüğüne başvuru yapılması gerekmektedir.
        </p>
    </AccordionTab>
    <AccordionTab header="Kimler yatırım yolu ile Türk Vatandaşlığı müracaatında bulunabilir?">
        <p className="m-0">
        4/4/2013 tarihli ve 6458 sayılı Yabancılar ve Uluslararası Koruma Kanununun 31 inci maddesinin birinci fıkrasının (j) bendi uyarınca ikamet izni alan yabancılar ile turkuaz kart sahipleri yatırım yolu ile vatandaşlığa başvurabilecektir.
        </p>
    </AccordionTab>
    {/* <AccordionTab header="Vatandaşlık Hukuku nedir?">
        <p className="m-0">
            Vatandaşlık hukuku, bir devlete ait olma durumunu ve buna bağlı hak ve yükümlülükleri düzenleyen hukuk dalıdır.
        </p>
    </AccordionTab>
    <AccordionTab header="Vatandaşlık kazanma yöntemleri nelerdir?">
        <p className="m-0">
            Vatandaşlık kazanma yöntemleri doğumla kazanma, doğal hukukla kazanma, ulusal hukukla kazanma, evlilik yoluyla kazanma, doğaüstü kazanma ve benzeri gibi çeşitli yöntemlerle olabilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Çifte vatandaşlık nedir?">
        <p className="m-0">
            Çifte vatandaşlık, bir kişinin aynı anda birden fazla devlete vatandaşlık bağı ile bağlı olması durumunu ifade eder. Bazı ülkeler çifte vatandaşlığı kabul ederken, bazıları ise sınırlamalar getirebilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Vatandaşlık hakları nelerdir?">
        <p className="m-0">
            Vatandaşlık hakları arasında seyahat özgürlüğü, oy kullanma hakkı, devlet yardımlarından faydalanma hakkı gibi çeşitli haklar bulunur.
        </p>
    </AccordionTab>
    <AccordionTab header="Vatandaşlık iptali ve kaybı süreçleri nasıl işler?">
        <p className="m-0">
            Vatandaşlık iptali ve kaybı genellikle yasa dışı faaliyetlerde bulunanlar veya vatandaşlık şartlarını ihlal edenler için geçerli olabilir. Bu süreçler genellikle idari ve yargısal prosedürlere tabidir.
        </p>
    </AccordionTab>
    <AccordionTab header="Vatandaşlık hukuku ve insan hakları ilişkisi nedir?">
        <p className="m-0">
            Vatandaşlık hukuku, insan haklarıyla uyumlu olmalıdır. Vatandaşların eşit haklara sahip olması, ayrımcılığa karşı korunması gibi ilkeler vatandaşlık hukukunda önem taşır.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda vatandaşlık hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine vatandaşlık başvuruları, çifte vatandaşlık durumları, vatandaşlık iptali ve kaybı süreçlerinde hukuki danışmanlık ve temsil hizmetleri sunmaktadır.
        </p>
    </AccordionTab> */}
</Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default VatandaslikHukuku;
