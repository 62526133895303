import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const CezaHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | CEZA HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>CEZA HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            <b>Ekşioğlu Hukuk Büromuzun</b> tecrübeli avukatları olarak ceza hukukunun her alanında size avukatlık hizmeti sunmaktayız. Özellikle uygulamada görev yapmış avukat kadromuz alanında oldukça tecrübelidir. Soruşturma ve kovuşturma aşamasında, sonrasında da kanun yolları aşamasında, tüm hukuki haklarınızı korumaktayız. Bunun yanı sıra disiplin soruşturmalarında da sizlere gerekli hukuki desteği sağlamaktayız.
            </p>
            <br></br>
            <p>
            Ceza alanında yaptığımız hukuki faaliyetler şu şekilde sıralanabilir : Duruşmalarda güçlü temsil, savunma hazırlama, keşiflere katılma, bilirkişi raporlarını değerlendirme, suç duyurusunda bulunma, esas hakkında savunma yapma, uzlaştırma görüşmelerine katılma ve delil dosyası hazırlama.
            </p>
            <br></br>
            <p>
            Müşteki olarak başvurunuz halinde ise mağduriyetinizin giderilmesi ve kanuni haklarınızın korunması adına her türlü hukuki faaliyet yerine getirilir. Özellikle <b class="emphasize">ceza hukuku</b> davalarında, sözlü beyan usulü esas olduğu için gerek katılan gerekse de sanık avukatı olarak duruşmada temsil çok önem kazanmaktadır. Bu nedenle duruşma tecrübesi olan avukat kadromuzla size hukuki destek sunmaktan onur duyarız.
            </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    {/* <AccordionTab header="Ceza Hukuku nedir?">
        <p className="m-0">
            Ceza hukuku, suç ve cezaları düzenleyen, suçlu bulunan kişilere uygulanacak yaptırımları belirleyen hukuk dalıdır.
        </p>
    </AccordionTab>
    <AccordionTab header="Ceza Hukuku temel prensipleri nelerdir?">
        <p className="m-0">
            Ceza hukukunun temel prensipleri adalet, suçun kanuni olması, suç ve ceza arasındaki orantılılık, cezanın kişiselliği ve suçun maddi gerçekliğine dayanır.
        </p>
    </AccordionTab>
    <AccordionTab header="Ceza ve suç farkı nedir?">
        <p className="m-0">
            Suç, ceza hukuku kurallarına aykırı davranışlardır. Ceza ise bu davranışlara uygulanan hukuki yaptırımdır.
        </p>
    </AccordionTab>
    <AccordionTab header="Ceza hukuku hangi alanları kapsar?">
        <p className="m-0">
            Ceza hukuku, genel ceza hukuku, özel ceza hukuku ve ceza muhakemesi hukuku olmak üzere üç ana alana ayrılır. Genel ceza hukuku suçları, özel ceza hukuku ise özel suçları ve ceza muhakemesi hukuku ise ceza davalarının yargılanma sürecini düzenler.
        </p>
    </AccordionTab>
    <AccordionTab header="Suç unsurları nelerdir?">
        <p className="m-0">
            Suçun unsurları, failin kastı veya ihmalinin varlığı, eylemin kanuni tanımının gerçekleşmesi ve hukuka aykırılığın bulunmasıdır.
        </p>
    </AccordionTab>
    <AccordionTab header="Ceza hukuku yaptırımları nelerdir?">
        <p className="m-0">
            Ceza hukuku yaptırımları arasında hapis cezası, para cezası, güvenlik tedbirleri ve ıslah tedbirleri bulunur. Yaptırımlar, suçun niteliğine ve ağırlığına göre belirlenir.
        </p>
    </AccordionTab>
    <AccordionTab header="Ceza hukuku ve insan hakları ilişkisi nedir?">
        <p className="m-0">
            Ceza hukuku, insan haklarıyla uyumlu olmalıdır. Adil yargılanma hakkı, masumiyet karinesi, işkence yasağı gibi insan hakları ceza hukukunda önemli yer tutar.
        </p>
    </AccordionTab> */}
    <AccordionTab header="Nasıl suç duyurusunda bulunulur?">
        <p className="m-0">
        Şikayetçi olduğunuz kişi ile ilgili olarak öncelikle bir suç duyurusu dilekçesi hazırlamanız gereklidir. Bu dilekçenin başlık kısmı denilen üst kısmında, sizin ve şikayetçi olduğunuz kişi veya kişilerin açık kimlik ve adres bilgileri, suç tipi, suç tarihi ve yeri, delilleriniz ve tanık isimleri yer almalıdır. Açıklamalar başlığı altında ise size karşı yapılan eylemler ve suçun açıklaması yazılmalıdır. Daha sonra bu dilekçe ile size en yakın adliyeye gidip başvurunuzu gerçekleştirebilirsiniz. Hatta önceden e-imza tanımlamanız varsa, UYAP VATANDAŞ portalı üzerinden de başvuru yapabilirsiniz. Genel kural olarak suçun işlendiği yer Cumhuriyet Başsavcılığı’na başvuru yapılmalıdır. Ancak bulunduğunuz yer Cumhuriyet Başsavcılığı aracılığıyla da başvuru yapabilmeniz mümkündür. Başvuru yapacağınız yerde ön büro sistemi varsa başvurunuz Cumhuriyet Başsavcılığı ön bürosuna yapılmalıdır. Eğer ön büro sistemi yoksa Müracaat Savcısına başvuru yapmalısınız. Müracaat konusuna göre, ilgili savcılık bürosuna yönlendirilirsiniz. Müracaat konunuza göre ilgili büroda görevli Cumhuriyet Savcısı tarafından müşteki olarak ifadeniz alınarak, şikayetçi olduğunuz konu açıklatılır. Her hangi bir mazeretiniz veya bedensel kusurunuz varsa, doğrudan ilgili Cumhuriyet Savcısına ifade vermek suretiyle de suç duyurunuz gerçekleştirilebilir. <b>Büromuza başvurmanız halinde sizin adınıza en doğru ve hukuki bir şekilde itirazınızı yapmamız mümkündür.</b>
        </p>
    </AccordionTab>
    <AccordionTab header="Savcı, şikayetinizle ilgili takipsizlik kararı ( KYOK-Kamu Davası Açılmasına Yer Olmadığına Dair Karar ) verirse ne yapmalısınız?">
        <p className="m-0">
        Cumhuriyet Savcısı genel olarak başvurunuz ile ilgili olarak ya kamu davası açar ya da KYOK verir. Bu karar sizin yasal adresiniz olan MERNİS adresinize tebliğ edilir. Öncelikle bu nedenle eğer MERNİS adresinde oturmuyorsanız, hak kaybına uğramama adına MERNİS adresinizi güncellemelisiniz. KYOK size tebliğ edildiği tarihten itibaren 2 haftalık yasal süre içinde Sulh Ceza Hakimliği’ne başvuru yapmanız gereklidir. Bu başvurunuzu yazılı bir dilekçe ile yapabilirsiniz. Bu dilekçede, verilen kararın neden yanlış olduğuna ilişkin bilgi, belge ve delillerinizi sunmalısınız. Sulh Ceza Hakimliği tarafından bu konuda verilen karar kesindir. Bu karara itiraz edilemez. <b>Büromuza başvurmanız halinde sizin adınıza en doğru ve hukuki bir şekilde itirazınızı yapmamız mümkündür.</b>
        </p>
    </AccordionTab>
    <AccordionTab header="Bir suç ile suçlandığınızda kanuni haklarınız nelerdir?">
        <p className="m-0">
        Öncelikle böyle bir durumda ilk tavsiyemiz bir avukat tutmanız olacaktır. Zira hukuki haklarınız en iyi bir şekilde bu şekilde güvence altına alınacaktır. Ancak bununla birlikte bir avukat tutma mecburiyetiniz de bulunmamaktadır. Üzerinize atılı suçun kanunda öngörülen cezasının alt sınırı en az 5 yıl ise zaten hukuki olarak avukatsız olarak ifadenizin alınması mümkün değildir. Maddi durumunuzun el vermemesi durumunda, bulunulan yer barosu tarafından size bir müdafi atanacaktır. Bir avukatınız olsa dahi ifade esnasında kendinizi en iyi şekilde savunmanız tavsiye edilir. İfade öncesinde avukatınız ile bir ön görüşme yapma hakkınız bulunmaktadır. Bu şekilde ifade öncesinde bir strateji belirlemeniz mümkündür. Eğer avukat tutmadan ifade verecekseniz, bu durumda da kanuni haklarınız bulunduğunu unutmayın. Öncelikle susma hakkınız bulunmaktadır. Bu hakkınız hem emniyet, hem savcılık hem de hakim karşısında dahi geçerlidir. Ancak susma hakkınızı kullanmanız durumunda, dosya içinde bulunan bilgi, belge ve delillere göre karar verilir. Bu nedenle susma hakkını kullanmanın lehinize mi yoksa aleyhinize mi olacağı konusunda önemli bir değerlendirme yapmalısınız. Bunun dışında, soruşturma ve kovuşturma aşamasında bir avukatın sahip olduğu tüm savunma ve talep haklarına şüpheli veya sanık olarak sizin de sahip olduğunuzu unutmayın. Örneğin, tanık dinletme, bilirkişi incelemesi isteme, savunma için süre isteme gibi yasal haklarınız bulunmaktadır. <b>Büromuza başvurmanız halinde ise suçlandığınız andan itibaren sizin adınıza en doğru ve hukuki bir şekilde savunmanızı yapmamız mümkündür.</b>
        </p>
    </AccordionTab>
    {/* <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda ceza hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine ceza hukuku alanında savunma hizmetleri, ceza davalarında temsil, ceza hukuku danışmanlığı ve ceza hukukuyla ilgili geniş kapsamlı hizmetler sunmaktadır.
        </p>
    </AccordionTab> */}
</Accordion>


          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default CezaHukuku;
