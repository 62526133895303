import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const SporHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
      <title>HİZMETLERİMİZ | SPOR HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SPOR HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Spor Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/sporHukuku.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Spor hukuku; tüm spor branşlarındaki sporcular, kulüpler ve federasyonlar arasında ortaya çıkan
            uyuşmazlıklara, hukukun genel ilkeleriyle bağdaşır çözümler bulmayı amaçlayan, tüm işlemlerin
            ulusalve uluslararası hukuk normlarına uygunluğunu sağlayan ve özel hukukun bir dalı olarak kabul
            edilir.
            </p>
            <br></br>
            <p>
            Büromuz, spor alanında Spor Genel Müdürlüğü’ne bağlı tüm spor federasyonları ve özellikle Futbol
Federasyonu, Tahkim kurulları ve mevzuatın gerektirdigi diğer kurum ve kuruluslara müvekkillerce
yapılacak başvurularda süreç tamamlanıncaya kadar danışmanlık hizmeti verir. Ayrıca kurullarca yapılan
her türlü düzenleme ve kurul kararları hakkında hukuki mütalaa verilerek gerektiginde itiraz ve dava
yollarında müvekkiller temsil edilir.
            </p>
            



            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
            <AccordionTab header="Sporcu için avukat şart mı?">
              <p className="m-0">
              Avukat, sporcular ve diğer spor profesyonellerinin haklarını korumak ve hukuki sorunlarını çözmek için
önemli bir rol oynamaktadır. Bu nedenle, sporla ilgilenen kişilerin, hukuki sorunlarında spor avukatı ile
görüşmesi tavsiye edilir.
              </p>
            </AccordionTab>
            <AccordionTab header="Spor Hukuku alanında sunulabilecek hizmetler nelerdir?">
              <p className="m-0">
              1-Spor kulübü sahipliği ve üyeler ile kulüpler arasındaki ilişkiler<br/>
2-Sporcu transfer sözleşmeleri<br/>
3-Basın ve medya kuruluşlarıyla spor müsabakaları yayın sözleşmeleri<br/>
4-Şirketlerle spor kulüpleri arasında sponsorluk sözleşmeleri<br/>
5-Spor kulüplerinin ticari faaliyetleri<br/>
6-Doping<br/>
7-Şike<br/>
8-Teşvik primi<br/>
9-Spor müsabakalarını seyirden yasaklama<br/>
10-Sporda tahkim<br/>
11-Sporda sosyal güvenlik<br/>
12-Sporda vergilendirme<br/>
13-Spor federasyonlarının düzenleyici işlemleri<br/>
14-Spor Kulüplerinin şirketleşmesi<br/>
15-Futbolcu Temsilci sözlesmeleri konularında TFF, UEFA, FIFA basta olmak üzere tüm ulusal
veuluslararası federasyonlar ile kurullarında görülecek hukuki ve idari ihtilaflar
              </p>
            </AccordionTab>
            <AccordionTab header="Hukuk Ofisi Olarak Sunulabilecek Hizmetler Nelerdir?">
              <p className="m-0">
              1-Sporcuların, teknik direktörlerin kulüplerden olan hak ve alacaklarının tahsil edilebilmesi için
yapılacak her türlü hukuki iş ve işlemler,<br/>
2-Spor oyun kurallarına aykırı hareketten dolayı verilmiş ya da aykırı davranılmamış olmasına rağmen
hukuka aykırı bir şekilde verilen cezaların iptali ya da uygun cezanın verilmesi için yürütülen hukuki
sürecin takip edilmesi,<br/>
3-Hukuka aykırı olarak verilen cezaların neden oldugu zararın tazmin edilebilmesi için yürütülen hukuki
süreç,<br/>
4-Profesyonel sporcuların kulübe kazandırılması sürecinde sözleşmenin taraf haklarının korunması
nedeniyle sözlesmenin hazırlanması,PFDK ve AFDK’ya sevk edilen sporcu ile teknik adamların
haklarının korunması için hukukisürecin takibi,<br/>
5-Spor kulüpleri ile sponsorlar arasında yapılan sözleşmelerin hazırlanması,<br/>
6-Spor kulüpleri ile sponsorlar arasında çıkacak ihtilafların çözülmesi için hukuki sürecin takip edilmesi,<br/>
7-Spor kulübünün şirketleşme çalısmalarında gerekli hukuki desteğin ve takibin yapılması,<br/>
8- Sporcu ve spor kulüplerinin vergi cezalarına iliskin ortaya çıkabilecek hukuki ihtilafların taraflehine
çözülmesi için sürecin takibi,<br/>
9-Sporcu ve kulüplerin medya karsısında kisisel ve kolektif haklarının korunması için gerekli sürecin
takibi,<br/>
10-Yabancı ülkelerden gelen sporcuların ülkeye ve ülkenin hukukuna hızlı bir sekilde adapte olmasıiçin
gerekli egitimlerin verilmesi,<br/>
11-Spor kulüpleri ile yayıncı kurulus arasında yapılacak sözlesmenin hazırlanması,<br/>
12-Spor kulüpleri ile yayıncı kuruluş arasındaki çıkan ihtilafların çözümü için gerekli hukuki desteğin
sunulması,<br/>
13-Yabancı ülkelerden gelen ya da gelecek olan oyuncuların yerleşim izni ile vatandaşlık işlerinin takibi,<br/>
14-Transferler sonrasında sporcuların lisans çıkarma süreçlerinin takibi,<br/>
15-Uluslararası organizasyonların hazırlık sürecinden organizasyonun sonuna kadar hukuki desteğin
sağlanması,<br/>
16-Doping kullandıgı iddia edilen sporcu haklarının hem uluslararası platformlarda hem de
ulusalplatforma korunması için gerekli tedbirlerin alınması.
              </p>
            </AccordionTab>
            {/* <AccordionTab header="Aile Hukuku nedir?">
              <p className="m-0">
                Aile hukuku, aile içi ilişkileri ve bu ilişkilerden doğan hukuki sonuçları düzenleyen bir hukuk dalıdır. Bu alan, evlilik, boşanma, velayet, mal rejimi, nafaka ve soybağı gibi konuları kapsar.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile Hukuku kimleri kapsar?">
              <p className="m-0">
                Aile hukuku, evli çiftler, boşanmış eşler, çocuklar, ebeveynler ve diğer aile bireyleri arasındaki hukuki ilişkileri kapsar. Aile içi anlaşmazlıkların çözümünde ve aile bireylerinin haklarının korunmasında önemli bir rol oynar.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile Hukuku neden önemlidir?">
              <p className="m-0">
                Aile hukuku, aile bireylerinin hak ve yükümlülüklerini düzenler ve korur. Aile içi anlaşmazlıkların çözümü, çocukların haklarının korunması ve aile içi huzurun sağlanması açısından kritik öneme sahiptir.
              </p>
            </AccordionTab>
            <AccordionTab header="Boşanma davalarında neler yapılabilir?">
              <p className="m-0">
                Boşanma davalarında, evliliğin sona erdirilmesi, nafaka talepleri, velayet ve mal paylaşımı konuları ele alınır. Ekşioğlu Hukuk Bürosu, müvekkillerine boşanma sürecinde hukuki destek sağlamaktadır.
              </p>
            </AccordionTab>
            <AccordionTab header="Velayet davalarında ne yapılabilir?">
              <p className="m-0">
                Velayet davalarında, çocuğun hangi ebeveynle kalacağı ve ebeveynlerin çocuğa dair hak ve sorumlulukları belirlenir. Çocuğun en iyi menfaatlerinin gözetilmesi esastır ve Ekşioğlu Hukuk Bürosu bu süreçte müvekkillerine profesyonel hukuki destek sunar.
              </p>
            </AccordionTab>
            <AccordionTab header="Nafaka nedir ve nasıl talep edilir?">
              <p className="m-0">
                Nafaka, boşanma sonrası bir eşin diğerine veya çocuklarına maddi destek sağlamasıdır. Nafaka talebi, mahkemeye başvurarak yapılır ve nafakanın miktarı mahkeme tarafından belirlenir. Ekşioğlu Hukuk Bürosu, nafaka talepleri konusunda hukuki danışmanlık ve temsil hizmeti sunmaktadır.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile içi şiddet durumunda neler yapılabilir?">
              <p className="m-0">
                Aile içi şiddet durumunda, şiddet mağdurları korunma talepleriyle mahkemeye başvurabilir ve koruyucu tedbirler talep edebilir. Ekşioğlu Hukuk Bürosu, aile içi şiddet durumlarında hukuki destek ve danışmanlık hizmeti sunar.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile hukuku danışmanlık süreci nasıl işler?">
              <p className="m-0">
                Aile hukuku danışmanlık süreci, müvekkilin ihtiyaç ve durumunun değerlendirilmesiyle başlar. Ardından, hukuki gereklilikler doğrultusunda danışmanlık hizmeti sunulur ve gerekli hukuki adımlar atılır. Ekşioğlu Hukuk Bürosu, bu sürecin her aşamasında profesyonel destek sağlar.
              </p>
            </AccordionTab>
            <AccordionTab header="Ekşioğlu Hukuk Bürosu'na kimler başvurabilir?">
              <p className="m-0">
                Ekşioğlu Hukuk Bürosu'na, aile hukuku alanında hukuki danışmanlık ve destek almak isteyen herkes başvurabilir. Evli çiftler, boşanmış eşler, ebeveynler ve diğer aile bireyleri hukuki hizmetlerden faydalanabilirler.
              </p>
            </AccordionTab> */}
          </Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default SporHukuku;
