import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Navbars.module.scss";
import { Menubar } from "primereact/menubar";
import logo from "../../assets/eksiogluPictures/logo-dark.png";
import onlineRandevu from "../../assets/eksiogluPictures/eksiogluPortal.png";
import { Button, Container, Navbar } from "reactstrap";
import classNames from "classnames";
import { useSize } from "components/SizeControl/SizeControl";
import PortalModal from "components/Modals/PortalModal"

const WhiteNavbar = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setModalVisible(true);
  };

const {above960} = useSize()
  let scrollSize = 640;
  const [navbarColor, setNavbarColor] = useState(
    (document.documentElement.scrollTop > scrollSize ||
      document.body.scrollTop) > scrollSize
      ? ""
      : " navbar-transparent"
  );
  const [buyButtonColor, setBuyButtonColor] = useState(
    (document.documentElement.scrollTop > scrollSize ||
      document.body.scrollTop) > scrollSize
      ? "info"
      : "neutral"
  );
  const [logoStyle, setLogoStyle] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const updateNavbarColor2 = () => {
      if (
        document.documentElement.scrollTop > scrollSize ||
        document.body.scrollTop > scrollSize
      ) {
        setNavbarColor("");
        setBuyButtonColor("info");
        setLogoStyle(false);
      } else if (
        document.documentElement.scrollTop < scrollSize ||
        document.body.scrollTop < scrollSize
      ) {
        setNavbarColor(" navbar-transparent");
        setBuyButtonColor("neutral");
        setLogoStyle(true);
      }
    };
    window.addEventListener("scroll", updateNavbarColor2);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor2);
    };
  }, []);

  const toggleDropdowns = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const items = [
    {
      label: "ANA SAYFA",
      icon: "pi pi-fw pi-home",
      command: () => {
        window.location.href = "/";
      },
    },
    {
      label: "HAKKIMIZDA",
      icon: "pi pi-fw pi-info",
      command: () => {
        window.location.href = "/hakkimizda";
      },
    },
    {
      label: "HİZMETLERİMİZ",
      icon: "pi pi-fw pi-list",
      items: [
        
        {
          label: "SİGORTA (TAHKİM) HUKUKU",
          command: () => {
            window.location.href = "/sigorta-tahkim-hukuku";
          },
        },
        {
          separator: true,
        },
        {
          label: "ÖZEL HUKUK",
          items: [
            {
              label: "SÖZLEŞMELER VE BORÇLAR HUKUKU",
              command: () => {
                window.location.href = "/ozel-hukuk/sozlesmeler-ve-borclar-hukuku";
              },
            },
            {
              separator: true,
            },
            {
              label: "AİLE HUKUKU",
              command: () => {
                window.location.href = "/ozel-hukuk/aile-hukuku";
              },
            },
            
            {
              separator: true,
            },
            {
              label: "GAYRİMENKUL HUKUKU",
              command: () => {
                window.location.href = "/ozel-hukuk/gayrimenkul-hukuku";
              },
            },
            
            {
              separator: true,
            },
            
            {
              label: "MİRAS HUKUKU",
              command: () => {
                window.location.href = "/ozel-hukuk/miras-hukuku";
              },
            },
            {
              separator: true,
            },
            {
              label: "TİCARET HUKUKU",
              command: () => {
                window.location.href = "/ozel-hukuk/ticaret-hukuku";
              },
            },
            
          ],
        },
        {
          label: "KAMU HUKUKU",
          items: [
            {
              separator: true,
            },
            {
              label: "CEZA HUKUKU",
              command: () => {
                window.location.href = "/kamu-hukuku/ceza-hukuku";
              },
            },
            {
              separator: true,
            },
            {
              label: "İDARE HUKUKU",
              command: () => {
                window.location.href = "/kamu-hukuku/idare-hukuku";
              },
            },
            {
              separator: true,
            },
            {
              label: "VERGİ HUKUKU",
              command: () => {
                window.location.href = "/kamu-hukuku/vergi-hukuku";
              },
            },
            {
              separator: true,
            },
            {
              label: "GÜMRÜK HUKUKU",
              command: () => {
                window.location.href = "/kamu-hukuku/gumruk-hukuku";
              },
            },
            {
              separator: true,
            },
            {
              label: "VATANDAŞLIK HUKUKU",
              command: () => {
                window.location.href = "/kamu-hukuku/vatandaslik-hukuku";
              },
            },
          ],
        },
        {
          label: "EKONOMİ VE TİCARET HUKUKU",
          items: [
            {
              separator: true,
            },
            {
              label: "İCRA İFLAS HUKUKU",
              command: () => {
                window.location.href = "/ekonomi-ve-ticaret-hukuku/icra-iflas-hukuku";
              },
            },
            
            {
              separator: true,
            },
            {
              label: "REKABET HUKUKU",
              command: () => {
                window.location.href = "/ekonomi-ve-ticaret-hukuku/rekabet-hukuku";
              },
            },
            {
              separator: true,
            },
            {
              label: "DENİZ TİCARETİ HUKUKU",
              command: () => {
                window.location.href = "/ekonomi-ve-ticaret-hukuku/deniz-ticareti-hukuku";
              },
            },
            {
              separator: true,
            },
            
            {
              label: "FİKRİ MÜLKİYET HUKUKU",
              command: () => {
                window.location.href = "/ekonomi-ve-ticaret-hukuku/fikri-mulkiyet-hukuku";
              },
            },
          ],
        },
        {
          label: "SOSYAL VE İDARİ HUKUK",
          items: [
            {
              separator: true,
            },
            {
              label: "TÜKETİCİ HUKUKU",
              command: () => {
                window.location.href = "/sosyal-ve-idari-hukuk/tuketici-hukuku";
              },
            },
            {
              separator: true,
            },
            
            {
              label: "İŞ VE SOSYAL GÜVENLİK HUKUKU",
              command: () => {
                window.location.href = "/sosyal-ve-idari-hukuk/is-ve-sosyal-guvenlik-hukuku";
              },
            },
            {
              label: "SAĞLIK HUKUKU",
              command: () => {
                window.location.href = "/sosyal-ve-idari-hukuk/saglik-hukuku";
              },
            },
            {
              separator: true,
            },
            {
              label: "SPOR HUKUKU",
              command: () => {
                window.location.href = "/sosyal-ve-idari-hukuk/spor-hukuku";
              },
            },
            
          ],
        },
        {
          label: "TEKNOLOJİ VE İNTERNET HUKUKU",
          items: [
            {
              separator: true,
            },
            {
              label: "BİLİŞİM HUKUKU",
              command: () => {
                window.location.href = "/teknoloji-ve-internet-hukuku/bilisim-hukuku";
              },
            },
            {
              separator: true,
            },
            {
              label: "KİŞİSEL VERİLERİ KORUMA KANUNU",
              command: () => {
                window.location.href = "/teknoloji-ve-internet-hukuku/kisisel-verilerin-korunmasi-hukuku";
              },
            },

            
          ],
        },
      ],
    },
    {
      label: "MULTİMEDYA",
      icon: "pi pi-fw pi-video",
      items: [
        {
          separator: true,
        },
        {
          label: "BLOG PAYLAŞIMLARI",
          command: () => {
            window.location.href = "/blog";
          },
        },
        {
          separator: true,
        },
        // {
        //   label: "VİDEOLAR",
        //   command: () => {
        //     window.location.href = "/";
        //   },
        // },
      ],
    },
    {
      label: "İLETİŞİM",
      icon: "pi pi-fw pi-envelope",
      command: () => {
        window.location.href = "/iletisim";
      },
    },
  ];

  const start = (
    <Link to="/">
      <img
        className={
          logoStyle
            ? `${styles.logoBackground} ${styles.logo}`
            : `${styles.logo}`
        }
        src={logo}
        alt="logo"
        width={200}
        height={60}
        title="EKŞİOĞLU HUKUK"
        loading="eager"
        rel="preload"
      />
    </Link>
  );

  const end = (
    <div>
      <a href="#" className={styles.logoContainer} onClick={handleClick}>
        <img
          src={onlineRandevu}
          alt="ekşioğlu portal"
          width={150}
          height={45}
          className={styles.logo}
        />
      </a>
      <PortalModal visible={modalVisible} onHide={() => setModalVisible(false)} />
    </div>
  );

  const menubarClasses = classNames({
    scrolled: true,
    hovered: hovered,
    above960 : above960
  });

  return (
    <>
      <Navbar
        className={"fixed-top" + navbarColor}
        color="white"
        expand="lg"
        style={{
          paddingTop: "0px",
          paddingBottom: "5px",
          fontSize: "1rem",
          color: "white",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Menubar
          model={items}
          start={start}
          end={end}
          className={menubarClasses}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        />
      </Navbar>
    </>
  );
};

export default WhiteNavbar;
