import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import axios from 'axios'
import PostFrameworkUpdated from './PostFrameworkUpdated'
import Aos from "aos";
import Footer from "components/Footers/Footer";
import WhiteNavbar from "components/Navbars/WhiteNavbar"
import { Helmet } from "react-helmet-async";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

const BlogRouter = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    axios
      .get("https://eksiogluhukukofisi.pythonanywhere.com/postApi/posts/")
      .then((response) => {
        const updatedPosts = response.data.map(post => ({
          ...post,
          content: updateImageSrcs(post.content)
        }));
        setPosts(updatedPosts);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const updateImageSrcs = (htmlContent) => {
    // Regex to find all src attributes
    const regex = /src="\/media\/(.*?)"/g;
    const baseUrl = 'https://eksiogluhukukofisi.pythonanywhere.com';
    return htmlContent.replace(regex, (match, p1) => `src="${baseUrl}/media/${p1}"`);
  };
  return (
   
    <Routes>
      {
        posts.map((post) => (
          <Route
            key={post?.id}
            path={`${post?.slug}`}
            element={<PostFrameworkUpdated post={post} posts={posts}/>}
          />
        ))
      }
      
      
    </Routes>
  
  )
}

export default BlogRouter