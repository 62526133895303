import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const MirasHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | MİRAS HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>MİRAS HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/mirasHukuku.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Miras hukuku, mirasbırakanın ölümü ile sona ermeyen hak ve borçlara ilişkin hukuki durumları
            düzenleyen hukuk dalıdır. Şayet mülkiyet ölüm ile sona erseydi, bu durumda miras hukukuna gerek
            bulunmayacaktı.
            
            </p>
            <br></br>
            <p>
            Miras hukukunun temelinde mülkiyet hakkı ve devamlılığı yer almaktadır. Ölümle beraber mülkiyet
            hakkının sona ermesi ve mirasbırakanın malvarlığının devlete kalması söz konusu olsaydı bu durumda
            gerçek bir mülkiyet hakkından söz edilmesi söz konusu olmazdı.
            </p>
            <br></br>
            <p>
            Anayasanın 35. maddesine göre; “Herkes, mülkiyet ve miras haklarına sahiptir. Bunlar ancak kamu
            yararı amacıyla kanunla sınırlandırılabilir.” Bu maddeyle beraber kişilerin mülkiyet ve miras hakları
            güvence altına alınmıştır.
            </p>



            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
            <AccordionTab header="Miras Avukatı Ne İş Yapar?">
                <p className="m-0">
                Miras avukatı görevleri, temel anlamda ölen bir kişinin ardından mal paylaşımı yapılırken yaşanan veya
                yaşanabilecek hukuki anlaşmazlıkları çözmektir. Bu alanda miras avukatına ihtiyaç duyulan hukuki
                ihtilaflar ve düzenlenmesi gereken temel hususlar şunlardan oluşur: <br/><br/>
                Miras bırakanın mirasçısını gösteren belgenin hazırlanması, mirasçılık belgesi alınması,<br/>
                Veraset ilamının alınması<br/>
                Vasiyetname düzenlenmesi ve noterde işlemin tamamlanmasının takibi,<br/>
                Mirasçı atama sözleşmesinin hazırlanması,<br/>
                Mirasta mal paylaşımının hukuka uygun şekilde yapılması,<br/>
                Ölünceye kadar bakma sözleşmesi düzenlenmesi,<br/>
                Miras bırakan kişinin malvarlığı tespitini içeren tereke davasının açılması ve takibi,<br/>
                Mirasçılık belgesinin iptali davaları,<br/>
                Mirastan feragat sözleşmelerinin hazırlanması,<br/>
                Mirasın reddi (Reddi Miras) davasının açılması ve takibi,<br/>
                Muvazaalı işlemlere karşı iptal davaları, muris muvazaasına dayalı tapu iptal davası ve benzeri davaların
                yürütülmesi,<br/>
                Saklı payın korunması davasının yürütülmesi,<br/>
                Ortaklığın giderilmesi davasının açılması ve takibi,<br/>
                Mirasta iade davalarının yürütülmesi,<br/>
                Mirasta taksim sözleşmelerinin hukuka uygun bir şekilde hazırlanması,<br/>
                Miras hukukunda tenkis davası açılması ve takibi,<br/>
                Vasiyetnamenin tenfizi ve vasiyet iptali davalarının açılması ve takibi,<br/><br/>
                Anlaşılacağı üzere hukuki alanda hemen hemen herkesin başına gelebilecek hususların çözümünde etkili
                ve mesleki anlamda tecrübeli miras avukatına ihtiyaç duyulabilir. Miras avukatlarının bu özel hukuk
                dalında ne kadar deneyim kazanırlarsa o kadar başarılı hukuki süreç yönetebildikleri bilinir. Dolayısıyla
                miras hukuku kapsamında meydana gelen hukuki anlaşmazlıkların giderilmesinde herhangi bir hak
                kaybına uğramamak için avukatlardan destek alınmalıdır.
                </p>
            </AccordionTab>
            <AccordionTab header="Miras Avukatı Neden Önemlidir?">
                <p className="m-0">
                Miras avukatı ile çalışmanın önemi, özellikle gayrimenkul gibi yüksek maliyetli unsurların da devreye
                girdiği miras hukukunda hak kayıpları yaşamamak için ayrı bir yere sahiptir. Çünkü miras paylaşımları
                sırasında sıklıkla mirastan mal kaçırma gibi hukuka uygun olmayan hareketlere girilebilir. Mirasta saklı
                payı olan kişilerin karşılaştığı bu tür durumların hukuka uygun şekilde çözüme kavuşturulabilmesi için
                miras avukatı önemlidir.
                </p>
            </AccordionTab>
            <AccordionTab header="Miras hukuku alanında sunulan hizmetler nelerdir?">
                <p className="m-0">
                - Tenkis Davaları<br/>
                - Mirastan Mal Kaçırma (Muris Muvazaası) Davaları<br/>
                - Veraset İlamının alınması işlemleri<br/>
                - Tereke Tespiti, terekenin yönetilmesi davalarının miras avukatı olarak takibi<br/>
                - Mirasın reddi davaları<br/>
                - Miras Ortaklığının giderilmesi davası (İzale-i Şuyu)<br/>
                - Mirastan feragat sözleşmesi<br/>
                - Miras sözleşmesi, vasiyetname işlemleri<br/>
                - Miras şirketinin yönetimi<br/>
                </p>
            </AccordionTab>
            {/* <AccordionTab header="Miras Hukuku nedir?">
                <p className="m-0">
                    Miras hukuku, kişilerin vefatından sonra mal varlığının nasıl dağıtılacağını ve mirasçıların haklarını düzenleyen hukuk dalıdır.
                </p>
            </AccordionTab>
            <AccordionTab header="Miras Hukuku kimleri kapsar?">
                <p className="m-0">
                    Miras hukuku, vefat eden kişinin mirasçıları arasındaki hukuki ilişkileri düzenler. Tüm bireyler için geçerli olan bu hukuk dalı, mirasçıların hak ve yükümlülüklerini belirler.
                </p>
            </AccordionTab>
            <AccordionTab header="Miras Hukuku neden önemlidir?">
                <p className="m-0">
                    Miras hukuku, vefat eden kişinin mal varlığının adil bir şekilde paylaşılmasını sağlar ve mirasçıların haklarını korur. Ayrıca, miras bırakanın vasiyetnamesine uygun olarak miras işlemlerini düzenler.
                </p>
            </AccordionTab>
            <AccordionTab header="Mirasçılar arasındaki anlaşmazlıklar nasıl çözülür?">
                <p className="m-0">
                    Mirasçılar arasındaki anlaşmazlıklar genellikle mirasın paylaşımı ve vasiyetnameye ilişkin konularda ortaya çıkar. Bu durumlarda arabuluculuk, dava yoluna başvurma veya mirasçılar arasında uzlaşma sağlama gibi çözüm yolları bulunur.
                </p>
            </AccordionTab>
            <AccordionTab header="Miras hukuku danışmanlık süreci nasıl işler?">
                <p className="m-0">
                    Miras hukuku danışmanlık süreci, miras bırakanın varisleri veya mirasçı adaylarının hukuki durumlarının değerlendirilmesiyle başlar. Ardından, vasiyetname varsa bu belgenin geçerliliği ve mirasın dağıtımı hakkında danışmanlık hizmeti sunulur.
                </p>
            </AccordionTab>
            <AccordionTab header="Miras hukuku uygulamaları neleri kapsar?">
                <p className="m-0">
                    Miras hukuku uygulamaları, miras bırakanın mal varlığının devri, mirasın paylaşımı, vasiyetnamenin geçerliliği, mirasın reddi gibi konuları kapsar. Bu uygulamalar, hukuki süreçlerin düzenlenmesi ve mirasçıların haklarının korunması açısından önem taşır.
                </p>
            </AccordionTab>
            <AccordionTab header="Miras hukuku ve vergi mevzuatı ilişkisi nedir?">
                <p className="m-0">
                    Miras hukuku işlemleri sırasında mirasçılar arasında yapılan mal varlığı devirleri vergi mevzuatı açısından da önemlidir. Mirasçıların vergisel yükümlülükleri ve vergi avantajları konusunda danışmanlık hizmeti sunulur.
                </p>
            </AccordionTab>
            <AccordionTab header="Ekşioğlu Hukuk Bürosu'na miras hukuku konusunda kimler başvurabilir?">
                <p className="m-0">
                    Ekşioğlu Hukuk Bürosu'na, miras hukukuyla ilgili her türlü danışmanlık ve hukuki destek ihtiyacı olan bireyler, aileler, mirasçılar ve vasiyetname hazırlamak isteyen kişiler başvurabilir.
                </p>
            </AccordionTab> */}
        </Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default MirasHukuku;
