import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const TuketiciHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HAKKIMIZDA | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>TÜKETİCİ HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
                Tüketici hukuku, tüketicilerin korunması ve tüketici ile işletmeler arasındaki ilişkilerin düzenlenmesi amacıyla oluşturulan hukuk dalıdır. Hukuk büroları, müvekkillerinin tüketici hukukuyla ilgili ihtiyaçlarını karşılamak için sıkça başvurdukları profesyonel kurumlardır. Bu alanda öne çıkan ofislerden biri de, <b>Ekşioğlu Hukuk Bürosu</b>'dur, uzman avukatları ve geniş deneyimiyle bilinmektedir.
            </p>
            <br></br>
            <p>
                <b>Ekşioğlu Hukuk Bürosu</b>, müvekkillerine tüketici hukuku konusunda kapsamlı hukuki danışmanlık hizmetleri sunmakta ve uzman avukatlarıyla hizmet vermektedir. Müvekkillerinin tüketici hakları ihlalleri, tüketici sözleşmeleri, iade ve değişim talepleri gibi konularda haklarını korumak ve yasal süreçlerde destek sağlamak için tanınmaktadır.
            </p>
            <br></br>
            <p>
                <b class="emphasize">Tüketici Hukuku</b>, tüketicilerin alışveriş, hizmet alımı gibi süreçlerdeki haklarını korumayı ve tüketiciyi korumaya yönelik hukuki düzenlemeleri içerir. <b>Ekşioğlu Hukuk Bürosu</b>, tüketici şikayetlerinden tüketici mahkemelerine, tüketici haklarına aykırı uygulamalardan kaynaklanan ihtilafların çözümüne kadar geniş bir yelpazede tüketici hukuku hizmeti sunmakta ve müvekkillerine özel çözümler üretmektedir. Sonuç olarak, <b>Ekşioğlu Hukuk Bürosu</b>, tüketici hukuku alanında uzmanlığı ve müvekkillerine sunduğu kaliteli hizmet anlayışıyla öne çıkmaktadır.
            </p>



            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="Tüketici Hukuku nedir?">
        <p className="m-0">
            Tüketici hukuku, tüketicilerin haklarını koruyan ve tüketici ile satıcı veya hizmet sağlayıcı arasındaki ilişkileri düzenleyen hukuk dalıdır. Tüketici hukuku, ürün ve hizmetlerin kalitesi, güvenliği, fiyatı gibi konuları kapsar.
        </p>
    </AccordionTab>
    <AccordionTab header="Tüketici hakları nelerdir?">
        <p className="m-0">
            Tüketici hakları arasında mal veya hizmetin ayıplı olması durumunda değiştirme veya iade hakkı, ayıplı mal veya hizmet nedeniyle tazminat talebi, reklam ve tanıtımların doğru olması gibi haklar bulunur.
        </p>
    </AccordionTab>
    <AccordionTab header="Mesafeli satış nedir?">
        <p className="m-0">
            Mesafeli satış, tüketici ile satıcı arasında fiziksel bir temas olmadan yapılan satış işlemlerini ifade eder. İnternet üzerinden yapılan alışverişler bu kategoriye girer ve mesafeli satış hükümleri uygulanır.
        </p>
    </AccordionTab>
    <AccordionTab header="Tüketici hukuku ve reklam hukuku ilişkisi nedir?">
        <p className="m-0">
            Reklam hukuku, tüketicileri yanıltıcı veya haksız rekabete neden olabilecek reklam ve tanıtımları düzenler. Tüketici hukukuyla birlikte çalışarak tüketicilerin doğru bilgilendirilmesini sağlar.
        </p>
    </AccordionTab>
    <AccordionTab header="Tüketici şikayetleri nasıl çözümlenir?">
        <p className="m-0">
            Tüketici şikayetleri genellikle tüketici hakem heyetleri veya tüketici mahkemeleri aracılığıyla çözümlenir. Bu süreçlerde tüketici haklarının korunması ve tüketiciye adil bir çözüm sunulması hedeflenir.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda tüketici hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine tüketici hakları ihlalleri konusunda hukuki danışmanlık, tüketici şikayetlerinin çözümlenmesi, ayıplı mal veya hizmetten kaynaklı tazminat talepleri ve tüketici mahkemelerinde temsil hizmetleri sunmaktadır.
        </p>
    </AccordionTab>
</Accordion>


          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default TuketiciHukuku;
