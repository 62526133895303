import { useSize } from "components/SizeControl/SizeControl";
import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

// core components

function PictureHeader({imageHeader}) {
  let pageHeader = React.createRef();
  const {under1200,between849to767,under576} = useSize()
  const getWidth = () => {
    if (under576) return "5rem";
    if (between849to767) return "56rem";
    return "auto"; // Default width value
  };
  // React.useEffect(() => {
  //   if (window.innerWidth > 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // }, []);

  return (
    <>
      <div style={{
       
        maxHeight: "100vw",
       marginTop: "5rem"
      }}>
        <div
          className="page-header-image headerTemplate"
          style={{
            backgroundImage:`url(${imageHeader})`            
          }}
          ref={pageHeader}
        ></div>
        
      </div>
    </>
  );
}

export default PictureHeader;
