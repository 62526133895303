import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { useNavigate } from "react-router-dom";
import sozlesmeVeBorclar from "../../assets/eksiogluPictures/hizmetler/sozlesmeVeBorclar.png";
import isHukuku from "../../assets/eksiogluPictures/hizmetler/isHukuku.png";
import aileHukuku from "../../assets/eksiogluPictures/hizmetler/aileHukuku.png";
import mirasHukuku from "../../assets/eksiogluPictures/hizmetler/mirasHukuku.png";
import gayrimenkulHukuku from "../../assets/eksiogluPictures/hizmetler/gayrimenkulHukuku.png";
import idareHukuku from "../../assets/eksiogluPictures/hizmetler/idareHukuku.png";
import cezaHukuku from "../../assets/eksiogluPictures/hizmetler/cezaHukuku.png";
import denizTicaretiHukuku from "../../assets/eksiogluPictures/hizmetler/denizTicaretiHukuku.png";
import fikriMulkiyetHukuku from "../../assets/eksiogluPictures/hizmetler/fikriMulkiyetHukuku.png";
import gumrukHukuku from "../../assets/eksiogluPictures/hizmetler/gumrukHukuku.png";
import kisiselVerilerinKorunmasıHukuku from "../../assets/eksiogluPictures/hizmetler/kisiselVerilerinKorunmasiHukuku.png";
import rekabetHukuku from "../../assets/eksiogluPictures/hizmetler/rekabetHukuku.png";
import saglikHukuku from "../../assets/eksiogluPictures/hizmetler/saglikHukuku.png";
import sigortaTahkimHukuku from "../../assets/eksiogluPictures/hizmetler/sigortaTahkimHukuku.png";
import ticaretHukuku from "../../assets/eksiogluPictures/hizmetler/ticaretHukuku.png";
import tuketiciHukuku from "../../assets/eksiogluPictures/hizmetler/tuketiciHukuku.png";
import vatandaslikHukuku from "../../assets/eksiogluPictures/hizmetler/vatandaslikHukuku.png";
import vergiHukuku from "../../assets/eksiogluPictures/hizmetler/vergiHukuku.png";
import bilisimHukuku from "../../assets/eksiogluPictures/hizmetler/bilisimHukuku.png";
import sporHukuku from "../../assets/eksiogluPictures/hizmetler/sporHukuku.png";
import icraHukuku from "../../assets/eksiogluPictures/hizmetler/icraHukuku.png";




const DynamicCardSection = () => {
  const cardData = [
    {
      title: "Sözleşmeler ve Borçlar Hukuku",
      imageSrc: sozlesmeVeBorclar,
      link: "/ozel-hukuk/sozlesmeler-ve-borclar-hukuku",
    },
    {
      title: "Aile Hukuku",
      imageSrc: aileHukuku,
      link: "/ozel-hukuk/aile-hukuku",
    },
    {
      title: "İş ve Sosyal Güvenlik Hukuku",
      imageSrc: isHukuku,
      link: "/sosyal-ve-idari-hukuk/is-ve-sosyal-guvenlik-hukuku",
    },
    
    {
      title: "Miras Hukuku",
      imageSrc: mirasHukuku,
      link: "/ozel-hukuk/miras-hukuku",
    },
    {
      title: "Gayrimenkul Hukuku",
      imageSrc: gayrimenkulHukuku,
      link: "/ozel-hukuk/gayrimenkul-hukuku",
    },
    {
      title: "Ceza Hukuku",
      imageSrc: cezaHukuku,
      link: "/kamu-hukuku/ceza-hukuku",
    },
    {
      title: "İdare Hukuku",
      imageSrc: idareHukuku,
      link: "/kamu-hukuku/idare-hukuku",
    },
    {
      title: "Vergi Hukuku",
      imageSrc: vergiHukuku,
      link: "/kamu-hukuku/vergi-hukuku",
    },
    {
      title: "Gümrük Hukuku",
      imageSrc: gumrukHukuku,
      link: "/kamu-hukuku/gumruk-hukuku",
    },
    {
      title: "Vatandaşlık Hukuku",
      imageSrc: vatandaslikHukuku,
      link: "/kamu-hukuku/vatandaslik-hukuku",
    },
    {
      title: "Ticaret Hukuku",
      imageSrc: ticaretHukuku,
      link: "/ozel-hukuk/ticaret-hukuku",
    },
    {
      title: "Deniz Ticareti Hukuku",
      imageSrc: denizTicaretiHukuku,
      link: "/ekonomi-ve-ticaret-hukuku/deniz-ticareti-hukuku",
    },
    {
      title: "Rekabet Hukuku",
      imageSrc: rekabetHukuku,
      link: "/ekonomi-ve-ticaret-hukuku/rekabet-hukuku",
    },
    {
      title: "Fikri ve Sınai Mülkiyet Hukuku",
      imageSrc: fikriMulkiyetHukuku,
      link: "/ekonomi-ve-ticaret-hukuku/fikri-mulkiyet-hukuku",
    },
    {
      title: "Tüketici Hukuku",
      imageSrc: tuketiciHukuku,
      link: "/sosyal-ve-idari-hukuk/tuketici-hukuku",
    },
    {
      title: "Sağlık Hukuku",
      imageSrc: saglikHukuku,
      link: "/sosyal-ve-idari-hukuk/saglik-hukuku",
    },
    {
      title: "Spor Hukuku",
      imageSrc: sporHukuku,
      link: "/sosyal-ve-idari-hukuk/spor-hukuku",
    },
    {
      title: "Sigorta (Tahkim) Hukuku",
      imageSrc: sigortaTahkimHukuku,
      link: "/sigorta-tahkim-hukuku",
    },
    {
      title: "Bilişim Hukuku",
      imageSrc: bilisimHukuku,
      link: "/teknoloji-ve-internet-hukuku/bilisim-hukuku",
    },
    {
      title: "Kişisel Verilerin Korunması Hukuku",
      imageSrc: kisiselVerilerinKorunmasıHukuku,
      link: "/teknoloji-ve-internet-hukuku/kisisel-verilerin-korunmasi-hukuku",
    },
    {
      title: "İcra İflas Hukuku",
      imageSrc: icraHukuku,
      link: "/ekonomi-ve-ticaret-hukuku/icra-iflas-hukuku",
    },
  ];

  const navigate = useNavigate();

  return (
    <Container>
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          <div className="title">
            <h2
              className="title ml-auto mr-auto text-center"
              style={{
                color: "#123274",
                fontFamily: "Benguiat Regular",
              }}
            >
              UZMANLIK ALANLARIMIZ
            </h2>
          </div>
        </Col>
      </Row>
      <Row>
        {cardData.map((card, index) => (
          <Col md="3" key={index} data-aos="fade-up" data-aos-duration="1000">
            <Card className="card-profile card-plain">
              <div className="card-image">
                <a href={card.link} onClick={() => navigate(card.link)}>
                  <img
                    alt={card.title}
                    className="img img-raised rounded img-hover"
                    style={{ border: "1px solid black" }}
                    src={card.imageSrc}
                  />
                </a>
              </div>
              <div className="card-body">
                <h4>{card.title}</h4>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default DynamicCardSection;