import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const GumrukHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | GÜMRÜK HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>GÜMRÜK HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Gümrük hukuku, uluslararası alanda faaliyet gösteren şirketlerin ticari faaliyetleri nedeniyle mal ve eşyanın ulusal sınırlar ötesinden giriş ve çıkışını izlemek ve kontrol etmek amacıyla tasarlanmış bir hukuk dalıdır. Gümrük hukukunun kapsamına giren gümrük gibi idari uyuşmazlıklarda, bu uyuşmazlıklarla ilgili olarak zamanında ve doğru hukuki yardım alınması durumunda, idari uyuşmazlıklardan kaynaklanan mağduriyetlerin ve hak kayıplarının önüne geçilmesi mümkündür.
            </p>
            <br></br>
            <p>
            Gümrük mevzuatı çerçevesinde gümrük vergisi ve gümrük cezalarından kaynaklanan idari uyuşmazlıklarda; Bu uyuşmazlıklardan doğabilecek şikayet ve zararlar, uyuşmazlık çözümüne yönelik yasal prosedürlerin doğru şekilde uygulanmasıyla önlenebilir. İlgili kanunların hüküm ve prosedürlerine uyum titizlikle takip edilmektedir. Burada bu son derece önemlidir, çünkü en ufak bir dikkatsiz davranış bile ciddi sonuçlara ve cezai ve mali cezalara yol açabilir.
            </p>
            <br></br>
            <p>
            Gümrük mevzuatı ile ilgili riskler arasında mallara el konulmasından büyük idari para cezalarına, ulaşım araçlarına el konulmasından, özgürlüğü kısıtlayıcı yaptırımlardan vergi yaptırımlarına ve vergi kaçakçılığı suçlarına kadar uzanan ağır yaptırımlar yer alıyor
            </p>


            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="Gümrük Müdürlüğü Cezaları Nereye Ödenir?">
        <p className="m-0">
        Gümrük işlemleri sebebi ile ödenmesi gereken gümrük vergileri ve para cezalar kamu Gümrük Saymanlık Müdürlüklerine ve yetki verilmiş olan kamu bankaları aracılığı ile ödenebilmektedir. Söz konusu bankalar 2021 yılı için şunlardır:<br/><br/>
            - T.C. Ziraat Bankası A.Ş.<br/>
            - Türkiye Halk Bankası A.Ş.<br/>
            - Türkiye Vakıflar Bankası T.A.O.<br/>
            - Ziraat Katılım Bankası A.Ş.<br/>
            - Vakıf Katılım Bankası A.Ş.<br/>
          Posta ve hızlı kargo taşımacılığı ile ülkeye giren eşyalar hakkında uygulanan vergiler ise gönderiyi ileten operatöre ödenebilmektedir.
        </p>
    </AccordionTab>
    <AccordionTab header="Gümrük Vergisi Ödenmezse Ne Olur?">
        <p className="m-0">
        Kesinleşmiş gümrük vergisinin süresi içerisinde ödenmemesi halinde Amme Alacaklarının Tahsil Usulü Hakkında Kanun hükümleri uygulama alanı bulacaktır. Söz konusu kanuna göre, gümrük vergisine ek olarak vade tarihinden tahsil edildiği güne kadar geçen süreye bağlı olarak gecikme zammı uygulanacaktır.
        </p>
    </AccordionTab>
    <AccordionTab header="Gümrük Vergisi İçin Nereye Dava Açılır?">
        <p className="m-0">
        Gümrük vergileri ve cezalarına ilişkin olarak başvurulabilecek yollar şu şekildedir;<br/><br/>
        -Uzlaşma,
        -İtiraz,
        -İtirazın reddi halinde idari yargı mercilerine başvuru.<br/><br/>
        Gümrük vergileri veyahut cezalarına karşı olarak direkt olarak dava açılması söz konusu olmamaktadır. Bu hususta gerçekleştirilecek olan işlemler için öncelikle ilgili idareler nezdinde gerekli itirazların yapılması gerekmektedir. İlgili idare tarafından söz konusu itirazın reddedildiğine ilişkin kararın itiraz edene tebliği veyahut idarenin 30 günlük yasal süresi içerisinde yanıt vermemesi, yani zımni ret, halinde dava açma yoluna başvurulabilecektir.<br/>
        İtirazın gerçekleştirildiği ilgili idare tarafından itirazın reddedildiğine dair verilen karar itiraz edene tebliğ edilmesinin ardından dava açma süresi başlar. İtiraz eden ilgili tarafından söz konusu tebliğ edildiği günden itibaren 30 gün içerisinde gümrük vergisi ve para cezasına ilişkin olarak işlemin yapıldığı yerdeki Vergi Mahkemelerine dava açılabilecektir.
        </p>
    </AccordionTab>
    {/* <AccordionTab header="Gümrük Hukuku nedir?">
        <p className="m-0">
            Gümrük hukuku, mal ve hizmetlerin ülke sınırları içine giriş, çıkış ve geçişlerini düzenleyen hukuk dalıdır. Gümrük vergileri ve gümrük işlemleri bu hukuk dalı içinde yer alır.
        </p>
    </AccordionTab>
    <AccordionTab header="Gümrük Hukuku'nun temel prensipleri nelerdir?">
        <p className="m-0">
            Gümrük hukukunun temel prensipleri gümrük vergilerinin kanuni olması, eşitlik, tarafsızlık, güvenlik ve ticaretin serbestisi gibi ilkeleri içerir.
        </p>
    </AccordionTab>
    <AccordionTab header="Gümrük işlemleri neleri kapsar?">
        <p className="m-0">
            Gümrük işlemleri, ithalat ve ihracat işlemleri, gümrük beyannamesi düzenleme, gümrük vergilerinin ödenmesi gibi süreçleri kapsar.
        </p>
    </AccordionTab>
    <AccordionTab header="Gümrük vergileri ve yaptırımları nelerdir?">
        <p className="m-0">
            Gümrük vergileri, ithal edilen veya ihraç edilen mal veya hizmetler üzerinden alınan vergilerdir. Gümrük ihlallerinde ise cezai yaptırımlar uygulanabilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Gümrük denetim ve sorumlulukları nelerdir?">
        <p className="m-0">
            Gümrük denetimi, gümrük idaresinin mal ve hizmetlerin sınırlardan geçişini denetlemesi ve gerektiğinde kontrol etmesi sürecidir. Gümrük idaresinin sorumlulukları arasında gümrük işlemlerinin düzenli yürütülmesi ve güvenliğin sağlanması bulunur.
        </p>
    </AccordionTab>
    <AccordionTab header="Gümrük hukuku ve ticaret hukuku ilişkisi nedir?">
        <p className="m-0">
            Gümrük hukuku ve ticaret hukuku, uluslararası ticaretin düzenlenmesi ve ticari ilişkilerin gümrük işlemleriyle uyumlu hale getirilmesi açısından ilişkilidir.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda gümrük hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine gümrük mevzuatı danışmanlığı, gümrük ihlalleri ve cezaları konusunda hukuki destek, gümrük denetimleri ve sorumluluklarına ilişkin hukuki danışmanlık hizmetleri sunmaktadır.
        </p>
    </AccordionTab> */}
</Accordion>


          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default GumrukHukuku;
