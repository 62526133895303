import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import {RiTeamLine} from "react-icons/ri"
import { FaUserTie } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { FaNetworkWired } from 'react-icons/fa';
import { FaHandsHelping } from 'react-icons/fa';



import { SiTrustpilot } from "react-icons/si";
import {MdOutlineComputer,MdLanguage} from "react-icons/md"
import Aos from "aos";

const NedenBiz = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div style={{backgroundColor:"aliceblue"}}>
      <Container  style={{ fontFamily: "Nucleo Outline" }} >
      {/* <div style={{ height:"5rem"}}></div> */}
        <div className="title">
          <h2
            className="title ml-auto mr-auto text-center"
            style={{ color: "#123274" , fontFamily:"Benguiat Regular"}}
            data-aos="fade-right" data-aos-duration="1000"

          >
            NEDEN BİZİ SEÇMELİSİNİZ ?
          </h2>
          <br/>
        </div>
        <Row>
         <Col md="3">
            <div className="info info-hover" style={{cursor:"default"}}>
              <div className="icon icon-success icon-circle">
                <i className="now-ui-icons">
                    
                <FaUserTie />
                </i>
              </div>
              <h1 className="info-title" style={{ color: "#123274",fontSize:"1.4rem",fontWeight:"400" }}>
                <b>Deneyimli Kadro</b>
              </h1>
              <p className="description" style={{fontSize:"15px"}}>
              20 yılı aşkın hukuki danışmanlık ve dava çözümleri deneyimi, kendini geliştiren, uzman ve dinamik avukat kadrosu ile yanınızdayız.
              </p>
            </div>
          </Col>
          <Col md="3">
            <div className="info info-hover" style={{cursor:"default"}}>
              <div className="icon icon-info icon-circle">
                <i className="now-ui-icons">
                <FaGlobe/>
                </i>
              </div>
              <h1 className="info-title" style={{ color: "#123274",fontSize:"1.4rem",fontWeight:"400" }}><b>Uluslararası Standartlar</b></h1>
              <p className="description" style={{fontSize:"15px"}}>
              Hukuki çözümlerimizi, evrensel standartlara dayalı bir süreç yaklaşımıyla sunarak, uluslararası hukuk kurallarına hakimiyetimiz ile müvekkillerimize en iyiyi sağlıyoruz.
              </p>
            </div>
          </Col>
          <Col md="3">
            <div className="info info-hover" style={{cursor:"default"}}>
              <div className="icon icon-primary icon-circle">
                <i className="now-ui-icons">
                    <FaNetworkWired/>
                </i>
              </div>
              <h1 className="info-title" style={{ color: "#123274",fontSize:"1.4rem",fontWeight:"400" }}><b>Global Hizmet Ağı</b></h1>
              <p className="description" style={{fontSize:"15px"}}>
              Türkiye ve Avrupa ülkeleri başta olmak üzere dünya genelindeki müvekkillerimize geniş kapsamlı hukuki danışmanlık hizmetleri sunuyoruz.
              </p>
            </div>
          </Col>
          <Col md="3">
            <div className="info info-hover" style={{cursor:"default"}}>
              <div className="icon icon-warning icon-circle">
                <i className="now-ui-icons">
                    <FaHandsHelping/>
                </i>
              </div>
              <h1 className="info-title" style={{ color: "#123274",fontSize:"1.4rem",fontWeight:"400" }}><b>Müvekkil Odaklı Yaklaşım</b></h1>
              <p className="description" style={{fontSize:"15px"}}>
              İhtiyaçlarınıza özel çözümler üretiyor, sizinle iş birliği yaparak hukuki konularda güvenilir destek sağlıyoruz.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <br/><br/>
    </div>
  );
};

export default NedenBiz;
