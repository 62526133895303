import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const SaglikHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | SAĞLIK HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SAĞLIK HUKUKU</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Sağlık hukuku, sağlık hizmetleri sunan kişi ve kuruluşlar ile sağlık hizmetlerinden yararlanan bireyler
arasındaki ilişkileri düzenleyen, bu alanda doğan hak ve yükümlülükleri belirleyen hukuk dalıdır. Sağlık
hukuku, tıbbi müdahaleler, hasta hakları, sağlık çalışanlarının hak ve sorumlulukları gibi pek çok konuyu
kapsar.
            </p>
            <br></br>
            <p>
            <b>Bilinmesi Gerekli Temel Kavramlar Yönünden</b>
            </p>
            <br></br>
            <p>
            <b>1. Hasta Hakları:</b><br/>
            Bilgilendirme ve Bilgilendirilmiş Onam: Hastaların, sağlık durumları, uygulanacak tedaviler ve
            riskler konusunda tam olarak bilgilendirilme hakkı vardır. Her türlü tıbbi müdahale için hastanın
            rızası alınmalıdır.<br/>
            Gizlilik: Hastaların sağlık bilgileri gizli tutulmalıdır. Bu bilgiler, hastanın izni olmadan üçüncü
            kişilerle paylaşılmamalıdır.<br/>
            Sağlık Hizmetlerine Erişim: Her birey, eşit ve adil bir şekilde sağlık hizmetlerine erişim hakkına
            sahiptir.
            </p>
            
            

            </Col>
          </Row>
          <Row>
          <Col
              className="mr-auto mt-5 mt-md-3"
              md="12"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >

            <p>
            <b>2. Sağlık Çalışanlarının Hak ve Sorumlulukları:</b><br/>
            Mesleki Özen ve Dikkat: Sağlık çalışanları, mesleklerini icra ederken gerekli özeni ve dikkati
göstermelidir.<br/>
Eğitim ve Gelişim: Sağlık çalışanları, mesleki bilgilerini ve becerilerini sürekli olarak güncel
tutmalıdır.<br/>
Hastaların Haklarına Saygı: Sağlık çalışanları, hastaların haklarına saygı göstermeli ve bu hakları
ihlal etmemelidir.
            </p>
            <br></br>
            <p>
            <b>3. Tıbbi Müdahale ve Tedavi:</b><br/>
            Acil Durumlar: Acil durumlarda, hastanın rızası alınamasa bile, hayati tehlikeyi önlemek amacıyla
tıbbi müdahale yapılabilir.<br/>
Tedavi Süreci: Hastalara uygulanacak tedaviler, bilimsel ve etik kurallar çerçevesinde olmalıdır.
Tedavi sürecinde hastanın bilgilendirilmesi ve onayı alınmalıdır.
            </p>
            <br></br>
            <p>
            <b>Hasta Hakları Yönetmeliği</b><br/>
            Türkiye'de hasta hakları, "Hasta Hakları Yönetmeliği" ile düzenlenmiştir. Bu yönetmelik, hastaların
sağlık hizmetlerinden yararlanırken sahip oldukları hakları ve bu hakların nasıl korunacağını belirler.<br/>
Yönetmelikte yer alan başlıca haklar:<br/>
Hizmetten Genel Olarak Faydalanma Hakkı<br/>
Bilgilendirilme ve Bilgilendirilmiş Onam Hakkı<br/>
Mahremiyet Hakkı<br/>
Tedaviyi Reddetme ve Durdurma Hakkı<br/>
Güvenlik Hakkı<br/><br/>
Bu bilgiler Türk Tüketici Hukuku'nun genel hatlarını oluşturmakta olup detaylı bilgiye ihtiyacınız varsa
danışmanlık için, yardıma ihtiyacınız var ise başvuru, arabuluculuk ve dava süreçlerini yürütmemiz için
bize ulaşabilirsiniz.
            </p>

          </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="Bir hasta tedaviyi reddedebilir mi?">
        <p className="m-0">
        Evet, hastalar, kendilerine uygulanacak tedaviyi reddetme hakkına sahiptirler. Ancak acil durumlarda ve
hayati tehlike söz konusu olduğunda bu hak sınırlı olabilir
        </p>
    </AccordionTab>
    <AccordionTab header="Tıbbi hatalar durumunda ne yapılabilir?">
        <p className="m-0">
            Tıbbi hatalar, sağlık çalışanlarının kusurlu davranışlarından kaynaklanan durumlardır. Bu tür durumlarda
hastalar, hukuki yollara başvurarak tazminat talep edebilirler.
        </p>
    </AccordionTab>
    <AccordionTab header="Hasta bilgileri nasıl korunur?">
        <p className="m-0">
        Hasta bilgileri, gizlilik ilkesi gereği korunmalıdır. Bu bilgiler, hastanın izni olmadan paylaşılmamalıdır
ve sadece sağlık hizmetlerinin sunulması amacıyla kullanılmalıdır.
        </p>
    </AccordionTab>
    {/* <AccordionTab header="Sağlık Hukuku nedir?">
        <p className="m-0">
            Sağlık hukuku, sağlık hizmetleri sunumu, hasta hakları, tıbbi uygulamaların düzenlenmesi ve sağlık sektöründe yer alan tarafların haklarını koruyan hukuk dalıdır. Sağlık hukuku, sağlık hizmetlerinin kalitesini ve erişimini sağlamayı amaçlar.
        </p>
    </AccordionTab>
    <AccordionTab header="Hasta hakları nelerdir?">
        <p className="m-0">
            Hasta hakları, hastaların tıbbi müdahalelere, bilgiye erişim hakkı, onur ve mahremiyetin korunması gibi temel hakları içerir. Hasta hakları, sağlık hizmetlerinden yararlanan herkes için önemli bir koruma sağlar.
        </p>
    </AccordionTab>
    <AccordionTab header="Tıbbi malpraktis nedir?">
        <p className="m-0">
            Tıbbi malpraktis, sağlık hizmeti sunan profesyonellerin ihmali veya hatalı müdahaleleri sonucu ortaya çıkan zararları ifade eder. Tıbbi malpraktis durumlarında hasta veya yakınları tazminat talebinde bulunabilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Sağlık kuruluşlarının sorumlulukları nelerdir?">
        <p className="m-0">
            Sağlık kuruluşları, hasta güvenliği, sağlık hizmetlerinin kalitesi ve çalışanların eğitimi gibi konularda sorumluluk taşır. Sağlık hukuku bu kuruluşların yükümlülüklerini düzenler ve denetler.
        </p>
    </AccordionTab>
    <AccordionTab header="Sağlık bilgilerinin gizliliği ve korunması nasıl sağlanır?">
        <p className="m-0">
            Sağlık bilgileri, hasta mahremiyetinin korunması gereken önemli verilerdir. Sağlık hukuku, sağlık bilgilerinin gizliliğini sağlamak ve bu bilgilerin izinsiz kullanılmasını önlemek için çeşitli düzenlemeler içerir.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda sağlık hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine tıbbi malpraktis davaları, hasta hakları ihlalleri, sağlık kuruluşlarının hukuki danışmanlığı ve sağlık hukukuyla ilgili geniş kapsamlı hizmetler sunmaktadır.
        </p>
    </AccordionTab> */}
</Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default SaglikHukuku;
