
import WhiteNavbar from 'components/Navbars/WhiteNavbar'
import React, { useEffect } from 'react'
import { Badge, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet-async';
import { FaCheck } from "react-icons/fa";
import Footer from 'components/Footers/Footer';
import { HashLink } from 'react-router-hash-link';
import PictureHeader from 'components/PictureHeader/PictureHeader';
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg"
import Ekibimiz from 'components/Ekibimiz/Ekibimiz';

const AboutUs = () => {
  document.body.classList.add("sidebar-collapse");
  useEffect(() => {
    AOS.init();
  }, [])
  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin
  
    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector('.gt-current-lang img');
      const newImgSrc = 'https://cdn.gtranslate.net/flags/32/tr.png'; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement)
      imgElement.src = newImgSrc;
      imgElement.width="32"
        imgElement.height="32"
    }
  }, []);
  return (
    <>
    <Helmet>
      <title>HAKKIMIZDA | EKŞİOĞLU HUKUK</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="EKŞİOĞLU HUKUK"
        data-rh="true"
      />
      <link rel="canonical" href='https://www.eksiogluhukukofisi.com/hakkimizda' />
      <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="EKŞİOĞLU HUKUK" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.eksiogluhukukofisi.com/hakkimizda" />
    </Helmet>
    <WhiteNavbar navbarSelection={"about-us"}/>
    <PictureHeader  imageHeader={imageHeader}/>
    <div className="projects-5">
            <Container style={{ fontFamily: "Nucleo Outline" }}>
  
            <Row>
              <Col className="ml-auto" md="5">
                <Card
                  className="card-background card-background-product card-raised"
                  style={{
                    backgroundImage: "url(" + require("assets/about-us/3.png") + ")",
                    height: "57rem",
                    alignContent: "center"
                  }}
                >
                  <CardBody>
                    <CardTitle tag="h2">Ekşioğlu Hukuk Ofisi, hukuki meselelerinizi doğru şekilde tespit eder ve en uygun çözümleri sunar.</CardTitle>
                    <p className="card-description">
                      %100 memnuniyet garantisi
                    </p>
                    <Badge className="badge-neutral">Ekşioğlu Hukuk Ofisi &trade;</Badge>
                  </CardBody>
                </Card>
              </Col>
              <Col className="mr-auto mt-5 mt-md-3" md="5" data-aos="zoom-in" data-aos-duration="2000" style={{alignSelf:"center"}}>
                <div className="info info-horizontal">
                  <h3 style={{display:"none"}}>Ekşioğlu Hukuk Ofisi, hukuki meselelerinizi doğru şekilde tespit eder ve en uygun çözümleri sunar.</h3>
                  <div className="icon icon-info">
                    <i className="now-ui-icons">
                      <FaCheck/>
                    </i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">
                      <b>Vizyonumuz</b>
                    </h4>
                    <p className="description">
                      Ekşioğlu Hukuk Ofisi olarak, İstanbul'un kalbinde hizmet veren lider bir hukuk danışmanlık firması olarak, hukuk sektöründe dünya çapında tanınan ve tercih edilen bir firma olmayı amaçlıyoruz.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons">
                      <FaCheck/>
                    </i>
                  </div>
                  <div className="description">
                    <h4 className="info-title"><b>Misyonumuz</b></h4>
                    <p className="description">
                      Ticaret hukuku, aile hukuku, ceza hukuku gibi çeşitli alanlarda uzman ekibimizle sizlere en iyi hizmeti sunmak için çalışıyoruz. Kurumsal danışmanlıktan bireysel davalara kadar geniş bir yelpazede sunduğumuz hizmetlerle, sektöre yön veren öncü bir kuruluş olarak dünya standartlarında hukuki hizmet sunmayı misyon ediniyoruz.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons">
                      <FaCheck/>
                    </i>
                  </div>
                  <div className="description">
                    <h4 className="info-title"><b>Hizmet Kalitemiz</b></h4>
                    <p className="description">
                      Ekşioğlu Hukuk Ofisi olarak, ticaret hukuku, aile hukuku, ceza hukuku ve daha birçok alanda en uygun ve etkili çözümleri sunarak müvekkillerimizin haklarını koruyoruz. Uzmanlığımızı ve deneyimimizi bir araya getirerek, hukuki sorunlarınıza kaliteli ve ekonomik çözümler sunmaktayız.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col className="ml-auto order-2 mt-5 mt-md-0 order-md-1" data-aos="zoom-in" data-aos-duration="2000" md="5" style={{alignContent:"center"}}>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons">
                      <FaCheck/>
                    </i>
                  </div>
                  <div className="description">
                    <h4 className="info-title"><b>Uzman Kadro</b></h4>
                    <p className="description">
                      Ekşioğlu Hukuk Ofisi olarak, sadece hukuki danışmanlık hizmeti sunmakla kalmayıp, aynı zamanda müvekkillerimizin tüm hukuki ihtiyaçlarında yanlarında olan, konusunda profesyonel bir ekibe sahibiz.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons">
                      <FaCheck/>
                    </i>
                  </div>
                  <div className="description">
                    <h4 className="info-title"><b>Dava ve Takip</b></h4>
                    <p className="description">
                    Müvekkillerimizin davalarının ve diğer hukuki süreçlerinin tam zamanında, istenilen kalitede ve kusursuz şekilde yürütülmesini sağlıyoruz. Müvekkil memnuniyeti açısından en önde gelen hukuk firmalarındanız.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons">
                      <FaCheck/>
                    </i>
                  </div>
                  <div className="description">
                    <h4 className="info-title"><b>Online Altyapı</b></h4>
                    <p className="description">
                      Hukuk danışmanlık firması olarak, hukuki süreçleri destekleyen kapsamlı bir IT altyapısı ile müvekkillerimizin süreçlerini eş zamanlı olarak takip ediyor ve kalite kontrollerini gerçekleştiriyoruz.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="mr-auto mt-0 mt-md-5 order-1 order-md-2" md="5">
                <Card
                  className="card-background card-background-product card-raised"
                  style={{
                    backgroundImage: "url(" + require("assets/about-us/4.png") + ")"
                  }}
                >
                  <CardBody>
                    <CardTitle tag="h2">Ekşioğlu Hukuk Ofisi ile Tanışın</CardTitle>
                    <p className="card-description text-white">
                      En üst kalitede bir hukuk deneyimi sunuyoruz. Yenilikçi danışmanlık yöntemlerimiz ve modern teknolojilerimizle, müvekkillerimizin ihtiyaçlarına özel çözümler üretiyoruz. Kişiye özel hukuki planlar, detaylı değerlendirmeler ve sürekli takip ile her adımda yanınızdayız. Amacımız, hukuki haklarınızı en iyi şekilde destekleyerek yaşam kalitenizi artırmak ve sizlere güvenli, etkili ve konforlu bir hukuki süreç sunmaktır.
                    </p>
                    <Badge className="badge-neutral">Ekşioğlu Hukuk Ofisi &trade;</Badge>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Ekibimiz /> */}
              
            </Container>
          </div>
    <Footer/></>
  )
}

export default AboutUs